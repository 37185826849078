import React from "react";
const WarnSvg = ({ style = {} }) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5652"
      width="75"
      height="75"
      style={style}
    >
      <path
        d="M989.789 857.485 571.231 113.038c-26.448-47.041-92.57-47.041-119.018 0L33.655 857.485c-26.448 47.041 6.612 105.843 59.509 105.843L930.28 963.328C983.177 963.328 1016.238 904.527 989.789 857.485zM467.794 365.381c12.28-14.738 27.887-22.109 46.929-22.109 19.051 0 34.649 7.296 46.937 21.814 12.206 14.555 18.311 32.758 18.311 54.645 0 18.828-25.49 157.304-33.985 258.047l-61.4 0c-7.459-100.741-35.114-239.216-35.114-258.047C449.475 398.176 455.59 380.048 467.794 365.381zM560.791 844.404c-12.911 13.965-28.277 20.929-46.066 20.929-17.781 0-33.156-6.965-46.067-20.929-12.877-13.928-19.283-30.805-19.283-50.63 0-19.713 6.405-36.774 19.283-51.072 12.911-14.297 28.286-21.445 46.067-21.445 17.789 0 33.156 7.149 46.066 21.445 12.877 14.297 19.291 31.357 19.291 51.072C580.082 813.601 573.669 830.477 560.791 844.404z"
        p-id="5653"
        fill="#ffffff"
      ></path>
    </svg>
  );
};
export default WarnSvg;
